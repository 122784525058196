/** 1. Imports **/
import React from 'react'
import styled, { css } from 'styled-components'
import { _BlogPostThumbnailCardComponent } from './_blogPostThumbnailCardComponent'

/** 4. Styled component */
export const BlogPostThumbnailCard = styled(_BlogPostThumbnailCardComponent)<{ first: boolean }>`
  ${({ theme, first }) =>
    first
      ? css`
          grid-column: span 2;

          display: grid;
          grid-template-columns: 2fr 1fr;
          align-items: flex-end;

          @media only screen and (max-width: ${theme.breakPoint.m}px) {
            grid-column: span 1;
            grid-template-columns: 1fr;
          }
        `
      : null}
`
