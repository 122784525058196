/** 1. Imports **/
import React from 'react'
import { Link } from 'gatsby-plugin-react-i18next'

import { BlogPostNode } from '../../providers/types/blogPostNode'
import styled from 'styled-components'

/** 2. Types **/
type Props = {
  className?: string
  data: BlogPostNode
  first: boolean
  noExcerpt?: boolean
}

/** 3. Base component **/
const Component = ({ className, data, noExcerpt }: Props) => (
  <Link className={className} to={data.fields.path} language={data.fields.language}>
    <div id={'info'}>
      <h1>
        {data.fields.type === 'snippet' ? <span>snippet</span> : null}
        {data.frontmatter.title}
      </h1>
      {noExcerpt ? null : data.fields.type === 'snippet' ? (
        <p>{data.frontmatter.description}</p>
      ) : (
        <p>{data.excerpt.slice(0, 120) + '...'}</p>
      )}
      <div className={'tags'}>
        {data.frontmatter.tags.map((tag) => (
          <span key={tag}>#{tag}</span>
        ))}
      </div>
    </div>
  </Link>
)

export const _BlogPostThumbnailCardComponent = styled(Component)`
  > div#info {
    height: 100%;
    padding: 40px;
    background-color: white;
    border-radius: 8px;
    white-space: pre-wrap;
    word-break: break-word;
    box-shadow: rgba(0, 0, 0, 0.05) 0 1px 2px 0;

    > h1 {
      padding-bottom: 8px;
      width: fit-content;

      line-height: 1.4;
      font-size: ${({ data }) => (data.fields.language === 'ja' ? 2.2 : 2.4)}rem;
      font-weight: 700;

      > span {
        padding: 4px 8px;
        margin-right: 8px;
        vertical-align: middle;
        letter-spacing: 0.05rem;
        font-size: 1.2rem;
        color: white;
        background-color: ${({ theme }) => theme.color.disableGrey};
        border-radius: 8px;
      }
    }

    > p {
      margin: 32px 0;
      line-height: 1.8;
      font-weight: 400;
      font-size: ${({ data }) => (data.fields.language === 'ja' ? 1.4 : 1.6)}rem;
      color: ${({ theme }) => theme.color.subTextGrey};
    }

    > div.tags {
      display: flex;
      flex-wrap: wrap;
      line-height: 1.4;
      font-size: 1.6rem;
      word-break: break-word;
      > span {
        margin-right: 16px;
      }
    }

    :hover {
      h1 {
        color: ${({ theme }) => theme.color.accentMagenta};
        transition: color 200ms;
      }
    }
  }
`
